const QUERIES = {
  USERS_LIST: 'users-list',
  NEWS_LIST: 'news-list',
  CMS_LIST:'cms-list',
  QRATED_CONTENT_LIST: 'qrated-content-list',
  CATEGORY_LIST: 'category-list',
  SLIDER_LIST:'slidermanagement-list'
}

export {QUERIES}
